.container
{
	padding-left:40px;
	padding-right:40px;
}

.row
{
	margin: 0 auto;
	max-width: 1180px;
	width: 100%;
	position: relative;
}

.row:before, .row:after
{
	content: " ";
	/* 1 */
	display: table;/* 2 */
}

.row:after
{
	clear: both;
}

.row.collapse> .column, .row.collapse> .columns
{
	padding-left: 0;
	padding-right: 0;
}

.row.collapse .row
{
	margin-left: 0;
	margin-right: 0;
}

.row .row
{
	margin: 0 -0.9375rem;
	max-width: none;
	width: auto;
}

.row .row:before, .row .row:after
{
	content: " ";
	/* 1 */
	display: table;/* 2 */
}

.row .row:after
{
	clear: both;
}

.row .row.collapse
{
	margin: 0;
	max-width: none;
	width: auto;
}

.row .row.collapse:before, .row .row.collapse:after
{
	content: " ";
	/* 1 */
	display: table;/* 2 */
}

.row .row.collapse:after
{
	clear: both;
}

.column, .columns
{
	padding-left: 0.9375rem;
	padding-right: 0.9375rem;
	width: 100%;
	float: left;
}

.column+ .column:last-child, .columns+ .column:last-child, .column+ .columns:last-child, .columns+ .columns:last-child
{
	float: right;
}

.column+ .column.end, .columns+ .column.end, .column+ .columns.end, .columns+ .columns.end
{
	float: left;
}

.column, .columns
{
	position: relative;
	padding-left: 0.9375rem;
	padding-right: 0.9375rem;
	float: left;
}

.small-1
{
	width: 8.33333%;
}

.small-2
{
	width: 16.66667%;
}

.small-3
{
	width: 25%;
}

.small-4
{
	width: 33.33333%;
}

.small-5
{
	width: 41.66667%;
}

.small-6
{
	width: 50%;
}

.small-7
{
	width: 58.33333%;
}

.small-8
{
	width: 66.66667%;
}

.small-9
{
	width: 75%;
}

.small-10
{
	width: 83.33333%;
}

.small-11
{
	width: 91.66667%;
}

.small-12
{
	width: 100%;
}

.small-offset-0
{
	margin-left: 0 !important;
}

.small-offset-1
{
	margin-left: 8.33333% !important;
}

.small-offset-2
{
	margin-left: 16.66667% !important;
}

.small-offset-3
{
	margin-left: 25% !important;
}

.small-offset-4
{
	margin-left: 33.33333% !important;
}

.small-offset-5
{
	margin-left: 41.66667% !important;
}

.small-offset-6
{
	margin-left: 50% !important;
}

.small-offset-7
{
	margin-left: 58.33333% !important;
}

.small-offset-8
{
	margin-left: 66.66667% !important;
}

.small-offset-9
{
	margin-left: 75% !important;
}

.small-offset-10
{
	margin-left: 83.33333% !important;
}

.small-offset-11
{
	margin-left: 91.66667% !important;
}

.small-reset-order
{
	float: left;
	left: auto;
	margin-left: 0;
	margin-right: 0;
	right: auto;
}

.column.small-centered, .columns.small-centered
{
	margin-left: auto;
	margin-right: auto;
	float: none;
}

.column.small-uncentered, .columns.small-uncentered
{
	float: left;
	margin-left: 0;
	margin-right: 0;
}

.column.small-centered:last-child, .columns.small-centered:last-child
{
	float: none;
}

.column.small-uncentered:last-child, .columns.small-uncentered:last-child
{
	float: left;
}

.column.small-uncentered.opposite, .columns.small-uncentered.opposite
{
	float: right;
}

.row.small-collapse> .column, .row.small-collapse> .columns
{
	padding-left: 0;
	padding-right: 0;
}

.row.small-collapse .row
{
	margin-left: 0;
	margin-right: 0;
}

.row.small-uncollapse> .column, .row.small-uncollapse> .columns
{
	padding-left: 0.9375rem;
	padding-right: 0.9375rem;
	float: left;
}