textarea
{
    height: auto;
    min-height: 50px;
}

select
{
    width: 100%;
}

/* Standard Forms */

form
{
    margin: 0 0 1.625rem;
}

/* Using forms within rows, we need to set some defaults */

form .row .row
{
    margin: 0 -0.8125rem;
}

form .row .row .column, form .row .row .columns
{
    padding: 0 0.8125rem;
}

form .row .row.collapse
{
    margin: 0;
}

form .row .row.collapse .column, form .row .row.collapse .columns
{
    padding: 0;
}

form .row .row.collapse input
{
    -webkit-border-bottom-right-radius: 0;
    -webkit-border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

form .row input.column, form .row input.columns, form .row textarea.column, form .row textarea.columns
{
    padding-left: 0.8125rem;
}

/* Label Styles */

label
{
    color: #4d4d4d;
    cursor: pointer;
    display: block;
    font-size: 0.8125rem;
    font-weight: normal;
    line-height: 1.5;
    margin-bottom: 0;/* Styles for required inputs */
}

label.right
{
    float: none !important;
    text-align: right;
}

label.inline
{
    margin: 0 0 1.625rem 0;
    padding: 0.9375rem 0;
}

label small
{
    text-transform: capitalize;
    color: #676767;
}

/* Attach elements to the beginning or end of an input */

.prefix, .postfix
{
    border-style: solid;
    border-width: 1px;
    display: block;
    font-size: 0.8125rem;
    height: 3.1875rem;
    line-height: 3.1875rem;
    overflow: visible;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 2;
}

/* Separate prefix and postfix styles when on span or label so buttons keep their own */

span.prefix, label.prefix
{
    background: #f2f2f2;
    border-right: none;
    color: #333333;
    border-color: #cccccc;
}

span.postfix, label.postfix
{
    background: #f2f2f2;
    color: #333333;
    border-color: #cccccc;
}

/* We use this to get basic styling on all basic form elements */

select, input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea
{
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
    background-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-color: #c6b9c0;
    box-shadow: none;
    color: #907f87;
    display: block;
    /* -- text-transform: uppercase;
    letter-spacing: 0.3rem; --*/
    font-family: inherit;
    font-size: 18px;
    margin: 0 0 1.625rem 0;
    padding: 20px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: border-color 0.15s linear, background 0.15s linear;
    -moz-transition: border-color 0.15s linear, background 0.15s linear;
    -ms-transition: border-color 0.15s linear, background 0.15s linear;
    -o-transition: border-color 0.15s linear, background 0.15s linear;
    transition: border-color 0.15s linear, background 0.15s linear;
}

select:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="email"]:focus, input[type="number"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="color"]:focus, textarea:focus
{
    background: transparent;
    border-color: #c6b9c0;
    outline: none;
    color: #907f87;
}

select:disabled, input[type="text"]:disabled, input[type="password"]:disabled, input[type="date"]:disabled, input[type="datetime"]:disabled, input[type="datetime-local"]:disabled, input[type="month"]:disabled, input[type="week"]:disabled, input[type="email"]:disabled, input[type="number"]:disabled, input[type="search"]:disabled, input[type="tel"]:disabled, input[type="time"]:disabled, input[type="url"]:disabled, input[type="color"]:disabled, textarea:disabled
{
    background-color: #DDDDDD;
    cursor: default;
}

select[disabled], select[readonly], fieldset[disabled] select, input[type="text"][disabled], input[type="text"][readonly], fieldset[disabled] input[type="text"], input[type="password"][disabled], input[type="password"][readonly], fieldset[disabled] input[type="password"], input[type="date"][disabled], input[type="date"][readonly], fieldset[disabled] input[type="date"], input[type="datetime"][disabled], input[type="datetime"][readonly], fieldset[disabled] input[type="datetime"], input[type="datetime-local"][disabled], input[type="datetime-local"][readonly], fieldset[disabled] input[type="datetime-local"], input[type="month"][disabled], input[type="month"][readonly], fieldset[disabled] input[type="month"], input[type="week"][disabled], input[type="week"][readonly], fieldset[disabled] input[type="week"], input[type="email"][disabled], input[type="email"][readonly], fieldset[disabled] input[type="email"], input[type="number"][disabled], input[type="number"][readonly], fieldset[disabled] input[type="number"], input[type="search"][disabled], input[type="search"][readonly], fieldset[disabled] input[type="search"], input[type="tel"][disabled], input[type="tel"][readonly], fieldset[disabled] input[type="tel"], input[type="time"][disabled], input[type="time"][readonly], fieldset[disabled] input[type="time"], input[type="url"][disabled], input[type="url"][readonly], fieldset[disabled] input[type="url"], input[type="color"][disabled], input[type="color"][readonly], fieldset[disabled] input[type="color"], textarea[disabled], textarea[readonly], fieldset[disabled] textarea
{
    background-color: #DDDDDD;
    cursor: default;
}

select.radius, input[type="text"].radius, input[type="password"].radius, input[type="date"].radius, input[type="datetime"].radius, input[type="datetime-local"].radius, input[type="month"].radius, input[type="week"].radius, input[type="email"].radius, input[type="number"].radius, input[type="search"].radius, input[type="tel"].radius, input[type="time"].radius, input[type="url"].radius, input[type="color"].radius, textarea.radius
{
    border-radius: 3px;
}

form .row .prefix-radius.row.collapse input, form .row .prefix-radius.row.collapse textarea, form .row .prefix-radius.row.collapse select, form .row .prefix-radius.row.collapse button
{
    border-radius: 0;
    -webkit-border-bottom-left-radius: 3px;
    -webkit-border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

form .row .prefix-radius.row.collapse .postfix
{
    border-radius: 0;
    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

form .row .postfix-radius.row.collapse input, form .row .postfix-radius.row.collapse textarea, form .row .postfix-radius.row.collapse select, form .row .postfix-radius.row.collapse button
{
    border-radius: 0;
    -webkit-border-bottom-left-radius: 3px;
    -webkit-border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

form .row .postfix-radius.row.collapse .postfix
{
    border-radius: 0;
    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

form .row .prefix-round.row.collapse input, form .row .prefix-round.row.collapse textarea, form .row .prefix-round.row.collapse select, form .row .prefix-round.row.collapse button
{
    border-radius: 0;
    -webkit-border-bottom-left-radius: 3px;
    -webkit-border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

form .row .prefix-round.row.collapse .postfix
{
    border-radius: 0;
    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

form .row .postfix-round.row.collapse input, form .row .postfix-round.row.collapse textarea, form .row .postfix-round.row.collapse select, form .row .postfix-round.row.collapse button
{
    border-radius: 0;
    -webkit-border-bottom-left-radius: 3px;
    -webkit-border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

form .row .postfix-round.row.collapse .postfix
{
    border-radius: 0;
    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

input[type="submit"]
{
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0;
}

/* Respect enforced amount of rows for textarea */

textarea[rows]
{
    height: auto;
}

/* Not allow resize out of parent */

textarea
{
    max-width: 100%;
    height: 215px;
}
::-webkit-input-placeholder {
 color: #848484;
}
:-moz-placeholder {
    /* Firefox 18- */
    color: #848484;
}
::-moz-placeholder {
    /* Firefox 19+ */
    color: #848484;
}
:-ms-input-placeholder {
 color: #848484;
}

.mainlogo
{
    height: auto;
    width: 340px;
}

/* Add height value for select elements to match text input height */

select
{
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background-color: transparent;
    border-radius: 0;
    // background-image: url("../images/arrow2.svg");
    background-position: 96% center;
    background-size: 15px;
    background-repeat: no-repeat;
    border-style: solid;
    border-width: 2px;
    border-color: #c6b9c0;
    color: #907f87;
    font-family: inherit;
    font-size: 18px;
    line-height: normal;
    padding: 0 0 0 25px;
    border-radius: 0;
    height: 65px;
}
select::-ms-expand {
 display: none;
}

select.radius
{
    border-radius: 3px;
}

select:hover
{
    background-position: 96% center;
    background-size: 15px;
}

select:disabled
{
    background-color: #DDDDDD;
    cursor: default;
}

select:focus
{
    // background: url("../images/arrow2.svg") 96% center no-repeat;
    background-size: 15px;
}

select[multiple]
{
    height: auto;
}

/* Adjust margin for form elements below */

input[type="file"], input[type="checkbox"], input[type="radio"], select
{
    margin: 0 0 1.625rem 0;
}

input[type="checkbox"]+ label, input[type="radio"]+ label
{
    display: inline-block;
    margin-left: 0.8125rem;
    margin-right: 1.625rem;
    margin-bottom: 0;
    vertical-align: baseline;
}

/* Normalize file input width */

input[type="file"]
{
    width: 100%;
}

/* HTML5 Number spinners settings */


/* We add basic fieldset styling */

fieldset
{
    border: 0px none transparent;
    margin: 1.125rem 0;
    padding: 0;
}

fieldset legend
{
    text-align: left;
    width: 100%;
    background: transparent;
    font-weight: normal;
    margin: 0 auto 35px auto;
    padding: 0 0.1875rem 0 0;
}

/* Error Handling */

[data-abide] .error small.error, [data-abide] .error span.error, [data-abide] span.error, [data-abide] small.error
{
    display: block;
    font-size: 0.75rem;
    font-style: italic;
    font-weight: normal;
    margin-bottom: 1.625rem;
    margin-top: -1px;
    padding: 0.375rem 0.5625rem 0.5625rem;
    background: #f04124;
    color: #FFFFFF;
}

[data-abide] span.error, [data-abide] small.error
{
    display: none;
}

span.error, small.error
{
    display: block;
    font-size: 0.75rem;
    font-style: italic;
    font-weight: normal;
    margin-bottom: 1.625rem;
    margin-top: -1px;
    padding: 0.375rem 0.5625rem 0.5625rem;
    background: #f04124;
    color: #FFFFFF;
}

.error input, .error textarea, .error select
{
    margin-bottom: 0;
}

.error input[type="checkbox"], .error input[type="radio"]
{
    margin-bottom: 1.625rem;
}

.error label, .error label.error
{
    color: #f04124;
}

.error small.error
{
    display: block;
    font-size: 0.75rem;
    font-style: italic;
    font-weight: normal;
    margin-bottom: 1.625rem;
    margin-top: -1px;
    padding: 0.375rem 0.5625rem 0.5625rem;
    background: #f04124;
    color: #FFFFFF;
}

.error> label> small
{
    background: transparent;
    color: #676767;
    display: inline;
    font-size: 60%;
    font-style: normal;
    margin: 0;
    padding: 0;
    text-transform: capitalize;
}

.error span.error-message
{
    display: block;
}

input.error, textarea.error, select.error
{
    margin-bottom: 0;
}

label.error
{
    color: #f04124;
}