.button
{
	display: inline-block;
	min-width: 200px;
	letter-spacing: 0.3rem;
	color: #fff;
	background-color: transparent;
	border-color: #fff;
	border-width: 4px;
	border-style: solid;
	text-transform: uppercase;
	text-decoration: none;
	padding:24px 34px;
	margin: 0 0 1.11111rem;
	font-size: 20px;
	font-weight: normal;
	line-height: normal;
	position: relative;
	text-align: center;
	-webkit-transition: 250ms all ease;
	transition: 250ms all ease;
	-moz-transition: 250ms all ease;
	-o-transition: 250ms all ease;
	-ms-transition: 250ms all ease;
}

	@media (max-width: 400px)
	{
		.button {
			font-size: 18px;
		}
	}

.button:hover
{
	color: #000;
	background-color: #fff;
}

.button.small
{
	width: 50%;
}

.button.large
{
	width: 75%;
}

.button.dark
{
	color: #000;
	border-color: #000;
}

.button.dark:hover
{
	color: #fff;
	background-color: #000;
}