html
{
	position: relative;
	min-height: 100%;
}

*, *:before, *:after
{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

html
{
	margin: 0;
}

body
{
	background: #fff;
	color: #2e2e2e;
	cursor: auto;
	font-family: "Quarto A", "Quarto B";
	font-style: normal;
	font-weight: 300;
	line-height: 1.5;
	margin: 0;
	padding: 0;
}

a:hover
{
	cursor: pointer;
}

img
{
	max-width: 100%;
	height: auto;
}

img
{
	-ms-interpolation-mode: bicubic;
}

.left
{
	float: left !important;
}

.right
{
	float: right !important;
}

.clearfix:before, .clearfix:after
{
	content: " ";
	display: table;
}

.clearfix:after
{
	clear: both;
}

.hide
{
	display: none;
}

.invisible
{
	visibility: hidden;
}

.antialiased
{
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

img
{
	display: inline-block;
	vertical-align: middle;
}
