.menu
{
	display: block;
	background: transparent;
	padding: 35px 30px;
	margin: 35px 30px 30px 0;
	width: 100%;
	height: 74px;
	z-index: 50;
	position: absolute;
}

@media (max-width:992px)
{
	.menu {
		display: none;
	}
}

.menu:before, .menu:after
{
	content: " ";
	/* 1 */
	display: table;/* 2 */
}

.menu:after
{
	clear: both;
}

.menu .sub-nav
{
	display: inline-block;
	position: relative;
	float: right;
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 14px;
	letter-spacing: 3px;
	font-weight: 400;
	padding-right: 10px;

}

.menu .sub-nav li
{

	padding-right: 45px;
	margin-bottom: 0;
	float: left;
}


.menu .sub-nav a
{
	letter-spacing: 1px;
	line-height: 1.8em;
	text-decoration: none;
	color: #FFFFFF;
	margin: 0 5px;
	padding:15px 0 10px;
	font-size: 18px;
	font-family: 'visbycf-medium', 'Arial', sans-serif;
}

.menu .sub-nav li a:hover
{
	border-bottom: 2px solid #9a8478;
}


/* .menu .sub-nav a:after, .menu .sub-nav a:before
{
	content: '';
	width: 2px;
	height: 20px;
	background: #FFFFFF;
	display: inline-block;
	margin-bottom: 3px;
	vertical-align: middle;
	opacity: 0;
	-moz-opacity: 0;
	-khtml-opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	transform: translate(0, 0);
	-webkit-transition: 250ms all ease;
	transition: 250ms all ease;
	-moz-transition: 250ms all ease;
	-o-transition: 250ms all ease;
	-ms-transition: 250ms all ease;
}

.menu .sub-nav a:hover:after, .menu .sub-nav a:hover:before
{
	opacity: 1;
	-moz-opacity: 1;
	-khtml-opacity: 1;
	filter: alpha(opacity=100);
}

.menu .sub-nav a:hover:after
{
	-webkit-transform: translate(15px, 0);
	-ms-transform: translate(15px, 0);
	transform: translate(15px, 0);
}

.menu .sub-nav a:hover:before
{
	-webkit-transform: translate(-15px, 0);
	-ms-transform: translate(-15px, 0);
	transform: translate(-15px, 0);
}

*/


header
{
	position: absolute;
	width: 100%;
}

header .header-logo
{
	float: left;
	width: 60px;
	opacity: 0;
	-moz-opacity: 0;
	-khtml-opacity: 0;
	filter: alpha(opacity=0);
}

.head-logo {
	position: absolute;
	top: 20px;
	left: 60px;
}

header .row
{
	position: relative;
}

body
{
	overflow-y: scroll;
	overflow-x: hidden;
}

body.noscroll
{
	overflow: hidden;
}

body.noscroll section#team, body.noscroll section#locals
{
	opacity: 0;
	-moz-opacity: 0;
	-khtml-opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transition: 350ms opacity ease, 350ms transform ease;
	transition: 350ms opacity ease, 350ms transform ease;
	-moz-transition: 350ms opacity ease, 350ms transform ease;
	-o-transition: 350ms opacity ease, 350ms transform ease;
	-ms-transition: 350ms opacity ease, 350ms transform ease;
}

h2
{
	font-size: 16px;
	line-height: 28px;
	color: #33b8b7;
	letter-spacing: 0px;
	margin-top: 0;
	text-transform: uppercase;
}

.contact-agent:last-child {
	padding-bottom: 50px;

}

a {
	color: #363234;
}

strong
{
	font-size: 1.15rem;
}

.highlight
{
	color: #85c2b6;
}

.subheading
{
	text-transform: uppercase;
	font-family: "ag_book_stencil", Cambria, "Times New Roman", Times, serif;
	font-size: 1.07rem;
	color: #A8A8A8;
	letter-spacing: 0.299rem;
	font-weight: normal;
	display: block;
	margin-bottom: 1.8rem;
	margin-top: 0.18rem;
	-webkit-font-smoothing: antialiased;
}

section
{
	padding: 150px 0;
}

section#team, section#locals
{
	opacity: 1;
	-moz-opacity: 1;
	-khtml-opacity: 1;
	filter: alpha(opacity=100);
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	transform: translate(0, 0);
	-webkit-transition: 350ms opacity ease 0.1s, 350ms transform ease 0.1s;
	transition: 350ms opacity ease 0.1s, 350ms transform ease 0.1s;
	-moz-transition: 350ms opacity ease 0.1s, 350ms transform ease 0.1s;
	-o-transition: 350ms opacity ease 0.1s, 350ms transform ease 0.1s;
	-ms-transition: 350ms opacity ease 0.1s, 350ms transform ease 0.1s;
}

.inner
{
	padding: 0.5375rem 0;
	float: left;
}

.inner img
{
	width: 100%;
}

.heading
{
	text-align: center;
	padding-bottom: 2rem;
}

.heading h2
{
	display: block;
}

.heading .subheading
{
	display: inline-block;
}

#hero-banner
{
	display: table !important;
	position: relative;
	background-image: url("../images/hero.jpg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-attachment: scroll;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	-ms-background-size: cover;
	background-size: cover;
	width: 100%;
	height: 500px;
}


#hero-banner .callout
{
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	width: 100%;
}

#hero-banner .callout .button
{
	margin: 110px 0 0 0;
	max-width: 310px;
	color: rgba(255, 255, 255, 1);
	background-color: rgba(255, 255, 255, 0);
	border-color: #fff;
	padding: 30px 35px;
}

@media (max-width: 320px)
{
	#hero-banner .callout .button
	{
		max-width: 250px;
		padding: 20px 25px;
	}
}

#hero-banner .callout .button:hover
{
	background-color: rgba(255, 255, 255, 1);
	color: rgba(255, 255, 255, 0);
}

#hero-banner .contact-container a.button:hover
{
	color: rgba(51, 178, 178, 1);
}

#hero-banner .contact-container a.button span
{
	font-weight: bold;
}

#hero-banner .logo-container
{
	padding-top: 75px;
	margin-bottom: 8rem;
}

#hero-banner .logo-container svg
{
	max-width:70%;
}

#hero-banner .contact-container a
{
	color: #ffffff;
	text-decoration: none;
}

#hero-banner .contact-container a.button:hover
{
	color: rgba(51, 178, 178, 1);
}

.horizontalSlide-container
{
	display: none;
}

#area .group
{
	float: left;
}

#area .group.left .info-container p
{
	width: 100%;
}

#area .verticalSlide-container2, #area .verticalSlide-container
{
	display: none;
}

#about,
#difference,
#where {
	background-color: #f2efe9;
}

#who {
	background-color: #363234;
}

#who p {
	color:#f2efe9;
}

#map-canvas
{
	width: 100%;
	height: 775px;
}

.background-seperator
{
	padding: 0;
}

.content
{
	cursor: pointer;
	position: relative;
	overflow: hidden;
	backface-visibility: hidden;
}

.content .namepos
{
	position: absolute;
	z-index: 2;
	text-align: center;
	min-height: 74px;
	width: 85%;
	margin-left: auto;
	margin-right: auto;
	bottom: 0;
	right: 0;
	left: 0;
	display: block;
	color: #FFFFFF;
	-webkit-transform: translate(0, -25px);
	-ms-transform: translate(0, -25px);
	transform: translate(0, -25px);
	-webkit-transition: 350ms transform ease 250ms;
	transition: 350ms transform ease 250ms;
	-moz-transition: 350ms transform ease 250ms;
	-o-transition: 350ms transform ease 250ms;
	-ms-transition: 350ms transform ease 250ms;
}

.content h5
{
	opacity: 0;
	-moz-opacity: 0;
	-khtml-opacity: 0;
	filter: alpha(opacity=0);
	-webkit-transform: translate(0, -10px);
	-ms-transform: translate(0, -10px);
	transform: translate(0, -10px);
	-webkit-transition: 350ms all ease 150ms;
	transition: 350ms all ease 150ms;
	-moz-transition: 350ms all ease 150ms;
	-o-transition: 350ms all ease 150ms;
	-ms-transition: 350ms all ease 150ms;
}

.content img
{
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: 1s transform ease;
	transition: 1s transform ease;
	-moz-transition: 1s transform ease;
	-o-transition: 1s transform ease;
	-ms-transition: 1s transform ease;
}


.content:hover .namepos
{
	-webkit-transform: translate(0, -55px);
	-ms-transform: translate(0, -55px);
	transform: translate(0, -55px);
	-webkit-transition: 350ms transform ease 50ms;
	transition: 350ms transform ease 50ms;
	-moz-transition: 350ms transform ease 50ms;
	-o-transition: 350ms transform ease 50ms;
	-ms-transition: 350ms transform ease 50ms;
}

.content:hover .gradient
{
	opacity: 0.4;
	-moz-opacity: 0.4;
	-khtml-opacity: 0.4;
	filter: alpha(opacity=40);
}

.content:hover h5
{
	-webkit-transform: translate(0, 5px);
	-ms-transform: translate(0, 5px);
	transform: translate(0, 5px);
	opacity: 1;
	-moz-opacity: 1;
	-khtml-opacity: 1;
	filter: alpha(opacity=100);
}

.content:hover img
{
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

#register
{
	color: #000;
	margin-top: 50px;
	padding: 110px 0 32px 0;
	background-color: #fff;
	background-image: url("");
}

@media (max-width:992px)
{
	#register
	{
		padding:0;
	}
}

#register fieldset .large-6
{
	padding:0 10px 0 0;
}

#register fieldset .large-6:nth-child(2n)
{
	padding:0 0 0 10px;
}

@media (max-width:991px)
{
	#register fieldset .large-6,
	#register fieldset .large-6:nth-child(2n)
	{
		padding:0;
	}

}


#register form
{
	width: 90%;
	margin: 0 auto 1rem auto;
	text-align: center;
}

.thankyou-template
{
	margin: 0 !important;
}

.thankyou #hero-banner
{
	border-bottom: none;
	width: 100%;
	height: 100vh;
}

.menu.fixed
{
	margin: 0;
	opacity: 0;
	-moz-opacity: 0;
	-khtml-opacity: 0;
	filter: alpha(opacity=0);
	background: #252222;
	position: fixed;
	left: 0;
	top: -74px;
	width: 100%;
	-webkit-transition: 150ms all ease;
	transition: 150ms all ease;
	-moz-transition: 150ms all ease;
	-o-transition: 150ms all ease;
	-ms-transition: 150ms all ease;
}

.sub-nav.scrollto.fixed
{
	margin-top: -10px;
	-webkit-transition: 150ms all ease;
	transition: 150ms all ease;
	-moz-transition: 150ms all ease;
	-o-transition: 150ms all ease;
	-ms-transition: 150ms all ease;
	float: right;
}

@media (max-width: 1295px)
{
	.sub-nav.scrollto.fixed a
	{
		letter-spacing: 1px;
	}

	.sub-nav.scrollto.fixed li
	{
		padding-right: 0px;
		margin: 0 20px 0 0;
	}
}


.sub-nav.scrollto.fixed a:hover
{
	background: none;
	color: #fff;
	opacity: 0.5;
	border-bottom: none;
}

.scrolled .menu.fixed
{
	top: 0;
	opacity: 1;
	-moz-opacity: 1;
	-khtml-opacity: 1;
	filter: alpha(opacity=100);
}

header
{
	position: absolute;
	width: 100%;
}

header .header-logo-scroll
{
	float: left;
	width: 130px;
	margin-top: -19px;
	opacity: 0;
	-moz-opacity: 0;
	-khtml-opacity: 0;
	filter: alpha(opacity=0);
}

.header-logo-scroll a img {
	width: 105px;
}

header .fixed .header-logo-scroll
{
	-webkit-transition: 250ms opacity ease 250ms;
	transition: 250ms opacity ease 250ms;
	-moz-transition: 250ms opacity ease 250ms;
	-o-transition: 250ms opacity ease 250ms;
	-ms-transition: 250ms opacity ease 250ms;
	opacity: 1;
	-moz-opacity: 1;
	-khtml-opacity: 1;
	filter: alpha(opacity=100);
}

header .row
{
	position: relative;
}

.site-by
{
	color: #907f87!important;
	font-size: 14px;
	text-align: center;
	margin-top: 48px;
	padding-top: 0;
	padding-bottom: 3em;
}

.site-by a
{
	text-decoration: none;
	color: #907f87!important;
}


.site-by p
{
	margin: 0;
}

/*# sourceMappingURL=style.css.map */

/* ------------------------------- SKELETON -----------------------------*/

.column, .columns
{
	position: relative;
	padding-left: 0.9375rem;
	padding-right: 0.9375rem;
	float: left;
}

 .large-1 img,
 .large-2 img,
 .large-3 img,
 .large-4 img,
 .large-5 img,
 .large-6 img,
 .large-7 img  {
	height: auto;
	width: 100%;
}

.large-1,
.large-2,
.large-3,
.large-4,
.large-5,
.large-6,
.large-7,
.large-8,
.large-9,
.large-10,
.large-11,
.large-12
{
	float:left;
}

.large-1
{
	width: 8.33333%;
}

.large-2
{
	width: 16.66667%;
}

.large-3
{
	width: 25%;
}

.large-4
{
	width: 33.33333%;
}

.large-5
{
	width: 41.66667%;
}

.large-6
{
	width: 50%;
}

.large-7
{
	width: 58.33333%;
}

.large-8
{
	width: 66.66667%;
}

.large-9
{
	width: 75%;
}

.large-10
{
	width: 83.33333%;
}

.large-11
{
	width: 91.66667%;
}

.large-12
{
	width: 100%;
}

.large-offset-0
{
	margin-left: 0 !important;
}

.large-offset-1
{
	margin-left: 8.33333% !important;
}

.large-offset-2
{
	margin-left: 16.66667% !important;
}

.large-offset-3
{
	margin-left: 25% !important;
}

.large-offset-4
{
	margin-left: 33.33333% !important;
}

.large-offset-5
{
	margin-left: 41.66667% !important;
}

.large-offset-6
{
	margin-left: 50% !important;
}

.large-offset-7
{
	margin-left: 58.33333% !important;
}

.large-offset-8
{
	margin-left: 66.66667% !important;
}

.large-offset-9
{
	margin-left: 75% !important;
}

.large-offset-10
{
	margin-left: 83.33333% !important;
}

.large-offset-11
{
	margin-left: 91.66667% !important;
}

.large-reset-order
{
	float: left;
	left: auto;
	margin-left: 0;
	margin-right: 0;
	right: auto;
}

.column.large-centered, .columns.large-centered
{
	margin-left: auto;
	margin-right: auto;
	float: none;
}

.column.large-uncentered, .columns.large-uncentered
{
	float: left;
	margin-left: 0;
	margin-right: 0;
}

.column.large-centered:last-child, .columns.large-centered:last-child
{
	float: none;
}

.column.large-uncentered:last-child, .columns.large-uncentered:last-child
{
	float: left;
}

.column.large-uncentered.opposite, .columns.large-uncentered.opposite
{
	float: right;
}

.row.large-collapse> .column, .row.large-collapse> .columns
{
	padding-left: 0;
	padding-right: 0;
}

.row.large-collapse .row
{
	margin-left: 0;
	margin-right: 0;
}

.row.large-uncollapse> .column, .row.large-uncollapse> .columns
{
	padding-left: 0.9375rem;
	padding-right: 0.9375rem;
	float: left;
}

.medium-12
{
	width: 100%;
}

/*-------- LS ADDED CSS --------*/

.header-small
{
	padding-bottom: 15px;
	padding-right: 0px;
	color: #9a8478;
	margin-bottom: 20px;
	position: absolute;
	top: -60px;
	font-size: 16px;
	line-height: 18px;
	text-transform: none;
	font-family: 'visbycf-medium', 'Arial', sans-serif;
}

p.write-up
{
	margin-top: 0!important;
	color: #907f87;
	font-size: 14px;
	line-height: 24px;
	padding-left: 20px;
}

.register
{
	text-align: left;
}


#register-row
{
	padding: 95px 40px 80px 40px;
}

@media (max-width:992px)
{
	#register-row
	{
		padding: 55px 40px 80px 40px;
	}
}

.padding-offset {
	padding-top: 20px;
}

#collage
{
	display:table;
}

#collage > div
{
	display:table-cell;
	float:none;
	vertical-align:top;
}

@media (max-width: 992px)
{
	#collage > div
	{
		display:block;
	}
}


#collage > div:nth-child(3)
{
	vertical-align:bottom;
}

@media (max-width: 992px)
{
	.stack-padding {
		padding-bottom: 20px!important;
	}
}

@media (max-width: 992px)
{
	.hide-small{
		display: none;
	}
}

/* ----- GROUP IMAGE CLUSTER ----- */

.large-5.inner.shops-group-text
{
	padding-left: 0;
}

.group-bottom-left
{
	padding-right: 10px;
	padding-top: 10px;
}

.group-bottom-right
{
	padding-left: 10px;
	padding-top: 10px;
}

.group-top-right
{
	padding-bottom: 10px;
}

.group-top-left
{
	padding-right: 10px;
	padding-bottom: 10px;
}

img.group-top-left
{
	width: 680px;
	height: auto;
}

img.group-bottom-left
{
	width: 380px;
	height: auto;
}

img.group-bottom-right {
	width: 280px;
	height: auto;
}

img.group-top-right
{
	width: 480px;
}

.group-pad {
	padding-right: 20px;
}

@media (max-width:992px)
{
	.group-pad {
		padding: 0;
	}
}

@media (max-width:500px)
{
	.group-pad {
		padding: 0;
	}
}


.bottom-button
{
	display: inline-block;
	min-width: 200px;
	letter-spacing: 2px;
	color: #363234;
	background-color: #e5dfd3;
	border-color: #e5dfd3;
	border-width: 4px;
	border-style: solid;
	text-transform: uppercase;
	text-decoration: none;
	padding: 15px 20px;
	margin: 10px 0 20px 0;
	font-size: 16px;
	line-height: normal;
	position: relative;
	text-align: center;
	-webkit-transition: 250ms all ease;
	transition: 250ms all ease;
	-moz-transition: 250ms all ease;
	-o-transition: 250ms all ease;
	-ms-transition: 250ms all ease;
	font-family: 'visbycf-medium', 'Arial', sans-serif;
}

@media (max-width: 400px)
{
	.bottom-button {
		font-size: 18px;
	}
}

.bottom-button:hover
{
	background-color: #363234;
	border-color: #363234;
	color: #fff;
}

.register-int
{
	padding-left: 0!important;
}

.footer-center
{
	padding-top: 30px;
	text-align: center;
}

#logo-footer
{
	padding-top: 60px;
	width: 200px;
	margin: 0 auto;
}

a.tandc
{
	color: #907f87!important;
	font-size: 12px;
	text-decoration: none;
	padding-top: 20px;
}

.footer-logo
{
	padding-top: 120px;
}

.form-headers
{
	color: #5a4d54;
	font-weight: bold;
	padding-bottom: 8px;
	margin-bottom: 18px;
	border-bottom: 1px solid #c6b9c0;
}

legend
{
	margin:0 auto;
}

#exp button
{
	text-indent: -9999px;
	border: none;
	outline: none;
}

.slick-arrow
{
	position: absolute;
	top: 50%;
	margin-top: -46px;
	height: 26px;
	width: 15px;
}

@media (max-width: 600px)
{
	.slick-prev, .slick-next
	{
		display: none !important;
	}
}

.slick-prev
{
	left: -40px;
	background: url("../images/arrow-left.png") 100% center no-repeat;
}

.slick-next
{
	right: -40px;
	background: url("../images/arrow-right.png") 100% center no-repeat;
}

ul.slick-dots
{
	list-style: none;
	margin: 30px auto;
	text-align: center;

}

@media (max-width:600px)
{
	ul.slick-dots
	{
		padding:0;
	}
}

ul.slick-dots li
{
	display: inline;
}

ul.slick-dots button
{
	background: url('../images/sprite-slider-nav-3.gif') bottom no-repeat;
	height: 8px;
	width: 8px;
	outline: none;
	padding-right: 15px;
}

li.slick-active button
{
	background: url('../images/sprite-slider-nav-3.gif') center no-repeat;
}

ul.slick-dots button:hover
{
	background: url('../images/sprite-slider-nav-3.gif') top no-repeat;
}

.bottom-left-bg
{
	position: absolute;
	bottom: 0;
	left: 0;
}

.bottom-right-bg
{
	position: absolute;
	bottom: 0;
	right: 0;
}

@media (max-width: 992px)
{
	.bottom-right-bg, .bottom-left-bg {
		display: none;
	}
}

.top-right-bg
{
	position: absolute;
	z-index: 0;
	margin-top: -150px;
	right: 0;
}

img.img-groupone-one
{
	float: left;
	width: 590px;
	max-width: 50%;
	display: block;
}

img.img-groupone-two
{
	float: left;
	width: 590px;
	max-width: 25%;
	display: block;
}

img.img-groupone-three
{
	float: left;
	width: 590px;
	max-width: 25%;
	display: block;
}

img.img-groupone-four
{
	float: left;
	width: 590px;
	max-width: 25%;
	display: block;
}

img.img-grouptwo-one
{
	width: 575px;
	height: auto;
	float: left;
}

img.img-grouptwo-two
{
	width: 575px;
	height: auto;
	float: right;
}

h1.section-header {
	text-transform: none;
	margin-top: 0;
	font-size: 53px;
	line-height: 54px;
	font-family: "Quarto A", "Quarto B";
	font-style: normal;
	font-weight: 600;
	color: #363234;
	letter-spacing: 0px!important;
}

h1.section-header::after {
	content: "";
	display: block;
	width: 120px;
	height: 2px;
	background-color: #363234;
	margin-top: 44px;
}

#google-map {
	height: 775px;
}

.align-left {
	padding-left: 0;
}

.grid-fourty {
	width: 465px;
	float: right;
}

.first-header
{
	top: 60px;
}

.second-form {
	padding-top: 20px;
}

#hero-banner
{
	height: 100vh;
	background-attachment: fixed;
}




#hero-banner .down-arrow
{
	display: block;
	position: absolute;
	bottom: 0;
	left: 50%;
	right: 0;
	width: 39px;
	margin-left: -19.5px;
	margin-bottom: 35px;
}

@media (max-height:700px)
{
	#hero-banner .down-arrow,
	.menu
	{
		display:none;
	}

}

@media (max-height:700px)
{
	#hero-banner
	{
		padding-bottom: 40px;
	}

}

#fifth-row img
{
	width:100%;
	display:block;;
}

.row .inner
{
	padding:0;
}

.slick-slide img
{
	min-width:100%;
	height:auto;
}

.callout-info
{
	display:none;
}

.callout-info-large
{
	position:absolute;
	bottom:90px;
	width:750px;
	left:50%;
	margin-left:-375px;
	text-align: center;
}

.callout-info-large::before {
	content: "";
	width: 180px;
	margin: 0 auto;
	border-top: 2px solid #9a8478;
	display: block;
	margin-bottom: 3em;
}

#hero-banner.thank .callout-info-large,
#hero-banner.thank .callout-info
{
	width:500px;
	margin-left:-250px;
}

#hero-banner.thank .callout-info {
	margin: 0 auto;
}

@media (max-width:992px)
{
	.callout-info-large
	{
		left:0;
		margin-left:0;
	}
	#hero-banner.thank .callout-info-large {
		right: 0;
		margin: 0 auto;
	}
}

.callout-info p,
.callout-info-large p
{
	color:#ffffff;
	font-size:26px;
	line-height:32px;
	font-weight:normal;
	-webkit-font-smoothing: antialiased;
	padding:0 2vw 5vh;
	margin:0;
	font-family: "Quarto A", "Quarto B";
	font-style: normal;
	font-weight: 300;
}

@media (max-width: 400px)
{
	.callout-info p,
	.callout-info-large p
	{
		font-size:23px;
		line-height: 38px;
	}
}



/** -------------------- MEDIA QUERIES -------------------------*/

/*-- Frame --*/

@media (max-width: 1200px) {
	.medium-1
	{
		width: 8.33333%;
	}

	.medium-2
	{
		width: 16.66667%;
	}

	.medium-3
	{
		width: 25%;
	}

	.medium-4
	{
		width: 33.33333%;
	}

/*--	.medium-5
	{
		width: 41.66667%;
	}
--*/
	.medium-6
	{
		width: 50%;
	}

	.medium-7
	{
		width: 58.33333%;
	}

	.medium-8
	{
		width: 66.66667%;
	}

	.medium-9
	{
		width: 75%;
	}

	.medium-10
	{
		width: 83.33333%;
	}

	.medium-11
	{
		width: 91.66667%;
	}

	.medium-12
	{
		width: 100%;
	}

}

@media (max-width: 992px) {
	.small-1
	{
		width: 8.33333%;
	}

	.small-2
	{
		width: 16.66667%;
	}

	.small-3
	{
		width: 25%;
	}

	.small-4
	{
		width: 33.33333%;
	}

	.small-5
	{
		width: 41.66667%;
	}

	.small-6
	{
		width: 50%;
	}

	.small-7
	{
		width: 58.33333%;
	}

	.small-8
	{
		width: 66.66667%;
	}

	.small-9
	{
		width: 75%;
	}

	.small-10
	{
		width: 83.33333%;
	}

	.small-11
	{
		width: 91.66667%;
	}

	.small-12
	{
		width: 100%;
	}
}

@media (max-width: 767px) {
	.xsmall-1
	{
		width: 8.33333%;
	}

	.xsmall-2
	{
		width: 16.66667%;
	}

	.xsmall-3
	{
		width: 25%;
	}

	.xsmall-4
	{
		width: 33.33333%;
	}

	.xsmall-5
	{
		width: 41.66667%;
	}

	.xsmall-6
	{
		width: 50%;
	}

	.xsmall-7
	{
		width: 58.33333%;
	}

	.xsmall-8
	{
		width: 66.66667%;
	}

	.xsmall-9
	{
		width: 75%;
	}

	.xsmall-10
	{
		width: 83.33333%;
	}

	.xsmall-11
	{
		width: 91.66667%;
	}

	.xsmall-12
	{
		width: 100%;
	}
}


/*-- Site --*/


@media (max-width: 1200px) {

		.thankyou #hero-banner
		{
			height: 100vh;
		}


		#hero-banner .callout .button
		{
			margin-top: 120px;
		}

		.menu
		{
			text-align:center;
		}

		.menu .sub-nav
		{
			float:none;
		}

		.menu .sub-nav li
		{
			display:inline;
			margin:0 15px;
			padding:0;
		}
}

@media (max-width: 992px) {

	.menu .sub-nav li
	{
		display:inline;
		margin:0 10px;
		padding:0;
	}

	.header-breaks
	{
		max-width:70%;
	}

	.header-breaks br
	{
		display: none;
	}

}

@media (max-width: 768px) {

		.menu
		{
			display:none;
		}



		#hero-banner .callout-text
		{
			margin: 5rem 0 2rem 0;
		}

		.thankyou #hero-banner
		{
			height: 100vh;
		}



		.right-text p
		{
			margin-left: 0.9rem;
		}

		#hero-banner .contact-text p.line4 span
		{
			display: inline-block;
		}

		#hero-banner .callout-text p
		{
			font-size: 2rem;
			-webkit-font-smoothing: antialiased;
		}

		#register form
		{
			width: 70%;
		}
}

/* =============================== ADDED FOR LUXTON ==========================*/


.heading-top {
	padding-top: 110px;
	padding-bottom: 40px;
}

.heading-nopara {
	padding-bottom: 60px;
}


.align-right {
	float: right;
}

h3 {
	color: #9a8478;
	font-size: 26px;
	line-height: 32px;
	font-style: normal;
	font-weight: 300;
}

.pad {
	padding: 0 20px;
}

.push {
	padding: 0 0 50px 0;
}

.image-set {
	padding: 0 0 60px 0;
}

h2, h3, p {
	margin: 0;
}

h2 {
	font-size: 46px;
	line-height: 48px;
	color: #9a8478;
	text-transform: none;
}

h2::after {
	content: "";
	display: block;
	width: 115px;
	height: 1px;
	background-color: #9a8478;
	margin: 30px 0;
}

h2::before {
	background: url('../images/triangle.png');
	display: block;
	width: 18px;
	height: 18px;
	margin: 30px 0;
	content: "";
}


p {
	color: #808285;
	font-family: 'visbycf-reg', 'Arial', sans-serif;
	line-height: 24px;
	size: 16px;
}

.column-pad p {
	width: 330px;
}

h1.white {
	color: #fff;
}

h1.white::after {
	background-color: #fff;
}

h3.who {
	color: #fff;
	font-size: 26px;
}

h3.who::after {
	content: "";
	display: block;
	width: 146px;
	height: 1px;
	background-color: #9a8478;
	margin: 12px 0;
}

h3.who.short::after {
	content: "";
	display: block;
	width: 108px;
	height: 1px;
	background-color: #9a8478;
	margin: 12px 0;
}

select, input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea
{
	font-family: 'visbycf-reg', 'Arial', sans-serif;
}

form .fields {
	margin: 0 10px 0 0!important;
}

form .fields.right-push {
	margin: 0 0 0 10px!important;
}

h3.contact {
	padding-bottom: 1em;
}

.contact-push {
	padding-left: 20px;
}

.contact-details {
	margin-left: 10px;
}

.contact-details p {
	padding-bottom: 1em;
}

.contact-details span {
	margin-right: 15px;
}

footer {
	background-color: #363234;
}

#footer {
	padding-top: 3em;
	padding-bottom: 2.2em;
}

#footer img {
	max-height: 50px;
	width: auto;
}

#footer p {
	font-size: 12px;
	color: #e5dfd3;
	padding-top: 34px;
}

#footer a {
	color: #e5dfd3;
	text-decoration: none;
}

@media (min-width: 992px) {
	.image-mob {
		display: none;
	}
}

@media (max-width: 1290px) {
	#exp button.slick-next.slick-arrow,
	#exp button.slick-prev.slick-arrow {
		display: none!important;
	}
}


@media (max-width: 1200px) {

	.head-logo {
		display: none;
	}

	h2::before {
		display: none;
	}

	.column-pad p {
		width: 100%;
		padding-bottom: 4em;
		padding-right: 20px;
	}

#difference > div.row.column-pad > div:nth-child(3) > p	{
		padding-bottom: 0;
		padding-right: 0;
	}

}

@media (max-width: 1112px) {

	.header-logo-scroll a img {
		display: none;
	}
}

@media (min-height: 900px) {

	.mob-intro {
		display: none;
	}
}


@media (max-height: 900px) {

	.mob-intro {
		display: block;
		padding-bottom: 2em;
	}

	section#about {
		padding-top: 150px;
	}

	.callout-info p
	{
		padding:0 0 4vh;
	}

	.callout-info-large
	{
		display:none;
	}

	.callout-info
	{
		display:block;
	}

	#hero-banner .callout .callout-info .button
	{
		margin:30px 0 0;
	}

	#hero-banner .logo-container
	{
		margin:0 auto 50px;
	}

}

@media (min-width: 992px) {

	.head-logo-mob {
		display: none;
	}
}

@media (max-width: 992px) {

	.head-logo-mob {
		display: inline-block;
		z-index: 89;
		position: relative;
		top: 1.75em;
		left: 1.75em;
	}


	p.write-up
	{
		padding-left:0;
	}

	.mob-logo {
		max-width: 50vw;
	}
	.image-desk {
		display: none;
	}

	.image-mob img {
		width: 100%;
	}

	.pad {
		padding: 15px 0;
	}

	.pad:last-of-type {
		padding: 0;
	}

	section
	{
		padding: 150px 0 100px 0;
	}

	form .fields {
		margin: 0!important;
	}

	form .fields.right-push {
		margin: 0!important;
	}

	.contact-details p {
		padding-right: 40px;
	}

	.contact-push {
		    padding-right: 20px;
	}
}

@media (max-width: 767px) {
	#footer {
		text-align: center;
	}

	#footer .mob-pull {
		padding-top: 0;
	}

	.container {
		padding-left: 20px!important;
		padding-right: 20px!important;
	}
}

@media (max-width: 450px) {
	#hero-banner .logo-container {
		margin-bottom: 4em;
	}

	.head-logo-mob img {
		height: 30px;
	}

 }
