/**
 *  THEME SPECIFIC
 */



/**
 *  BLINK GENERIC
 */

// Responsive
// Creates a media query based on the supplied preset values
// Usage:
//   @include responsive(bs, sm) { <content> } - Will create a responsive media query between bs and sm, usually 0 and 768px
//   @include responsive('', lg) { <content> } - Will create a responsive media query between 0 and lg
//   @include responsive(md) { <content> } - Will create a responsive media query md and up
// @author Chris Heath
@mixin responsive( $minVal: '', $maxVal: '' ) {

    $min: map-get( $theme-screen-breakpoints, $minVal );
    $max: map-get( $theme-screen-breakpoints, $maxVal );

    // Both sizes given
    @if ( $minVal != '' and $maxVal != '' ) {
        @media ( min-width: #{$min} ) and ( max-width: #{$max - 1} ) {
            @content;
        }
    }

    // Min only (min to infinity)
    @else if ( $minVal != '' and $maxVal == '' ) {
        @media( min-width: #{$min} ) {
            @content;
        }
    }

    // Max only (0 to max)
    @else if ( $minVal == '' and $maxVal != '' ) {
        @media( max-width: #{$max - 1} ) {
            @content;
        }
    }
}


// String Replace
// @source https://gist.github.com/jonathantneal/d0460e5c2d5d7f9bc5e6
@function str-replace( $string, $search, $replace: "" ) {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

// Font Face
// @source https://gist.github.com/jonathantneal/d0460e5c2d5d7f9bc5e6
@mixin font-face( $name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg ) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}


// Target IE 10 and 11 only.
// Usage:
// @include target-ie {
//    color: red;
// }
@mixin target-ie {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}
