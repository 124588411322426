/*--------------------------- CUSTOM FONTS ----------------------------*/

@font-face {
	font-family: 'visbycf-medium';
	src: url('../fonts/webfont/VisbyCF-Medium.woff2') format('woff2'),
	   url('../fonts/webfont/VisbyCF-Medium.woff') format('woff');
}

@font-face {
	font-family: 'visbycf-reg';
	src: url('../fonts/webfont/VisbyCF-Regular.woff2') format('woff2'),
		url('../fonts/webfont/VisbyCF-Regular.woff') format('woff');
}

meta.foundation-version
{
	font-family: "/5.5.2/";
}

meta.foundation-mq-small
{
	font-family: "/only screen/";
	width: 0;
}

meta.foundation-mq-small-only
{
	font-family: "/only screen and (max-width: 40em)/";
	width: 0;
}

meta.foundation-mq-medium
{
	font-family: "/only screen and (min-width:40.0625em)/";
	width: 40.0625em;
}

meta.foundation-mq-medium-only
{
	font-family: "/only screen and (min-width:40.0625em) and (max-width:64em)/";
	width: 40.0625em;
}

meta.foundation-mq-large
{
	font-family: "/only screen and (min-width:64.0625em)/";
	width: 64.0625em;
}

meta.foundation-mq-large-only
{
	font-family: "/only screen and (min-width:64.0625em) and (max-width:90em)/";
	width: 64.0625em;
}

meta.foundation-mq-xlarge
{
	font-family: "/only screen and (min-width:90.0625em)/";
	width: 90.0625em;
}

meta.foundation-mq-xlarge-only
{
	font-family: "/only screen and (min-width:90.0625em) and (max-width:120em)/";
	width: 90.0625em;
}

meta.foundation-mq-xxlarge
{
	font-family: "/only screen and (min-width:120.0625em)/";
	width: 120.0625em;
}

meta.foundation-data-attribute-namespace
{
	font-family: false;
}
