/************************************************************************

Theme Name: 	Blink Mobile Menu
Description: 	Mobile Menu - modular
Version: 	1.0
Author: 	Blink Creative
Author URI: 	https://blinkcreative.com.au
Tags: 		Mobile Menu


/*-----------------------------------------------------------------------

------------------------------------------------------------------------*/
.container {
	position: relative;
}

#frame-header {
    background:rgba(68, 68, 68, 0.9);
    position:absolute;
    top:0;
    width:100%;
    height:92px;
    z-index:9999;
}

#navigation-mobile {
    display:none;
    background: #363234;
    position:absolute;
    top:0;
    width:100%;
    height:100vh;
	z-index: 99;
}

#navigation-mobile .mobile-menu-quiz {
	background-color: #363234;
	padding: 16px 12px;
	color: #ffffff;
	text-align: center;
	border-radius: 32px;
}

#navigation-mobile .mobile-menu-quiz a {
	color: #ffffff;
}

.mobile-icon {
    position: absolute;
    cursor: pointer;
    top: 2em;
    right: 1.75em;
    z-index: 15;
}

@media (max-height: 767px) {
    #navigation-mobile {
        min-height: 100vh;
        height: auto;
    }

}

@media (min-width: 992px) and (min-height: 701px) {
    .mobile-icon {
        display: none;
    }
}

/*@media (max-width: 992px) {
    .mobile-icon {
        display: block;
    }
}*/

#navigation-mobile.active {
    display: -webkit-box;   /* OLD: Safari,  iOS, Android browser, older WebKit browsers.  */
    display: -moz-box;      /* OLD: Firefox (buggy) */
    display: -ms-flexbox;   /* MID: IE 10 */
    display: -webkit-flex;  /* NEW, Chrome 21?28, Safari 6.1+ */
    display: flex;
}

#navigation-mobile > ul {
    float:left;
    width:50%;
    padding:100px 0 100px 100px;
	list-style: none;
}

#navigation-mobile li {
	padding: 0 0 12px 0;
	margin: 0 0 32px 0;
	border-bottom: 1px solid #969696;
}

#navigation-mobile li {
	padding: 0 0 12px 0;
	margin: 0 0 32px 0;
	border-bottom: 1px solid #969696;
}

#navigation-mobile li a {
    font-style: normal;
    font-weight: 400;
    left: 0;
    letter-spacing: 3px;
    text-transform: none;
    color:#f2efe9;
    font-size: 20px;
    text-decoration:none;
	font-family: 'visbycf-medium', 'Arial', sans-serif;
}

#navigation-mobile li .line-grow {
    display: inline-block;
    background:#ffffff;
    width:0;
    margin:0;
    height:2px;
    -webkit-transition:-webkit-transform all 0.15s ease-in;
    -moz-transition:all 0.15s ease-in;
    -ms-transition:all 0.15s ease-in;
    -o-transition:all 0.15s ease-in;
    transition:all 0.15s ease-in;
}

#navigation-mobile li:hover .line-grow,
#navigation-mobile li.active .line-grow {
    width:40px;
    margin:0 10px 0 0;
}

#navigation-mobile .navigation-mobile-container {
    display: -webkit-box;   /* OLD: Safari,  iOS, Android browser, older WebKit browsers.  */
    display: -moz-box;      /* OLD: Firefox (buggy) */
    display: -ms-flexbox;   /* MID: IE 10 */
    display: -webkit-flex;  /* NEW, Chrome 21?28, Safari 6.1+ */
    display: flex;
    align-items: center;
    width:50%;
    position:absolute;
    left:50%;
    top:0;
    height:100vh;
    background:rgba(57,74,111,1);
    padding:100px;
    visibility:hidden;;
    -webkit-transition:-webkit-transform all 0.3s ease;
    -moz-transition:all 0.3s ease;
    -ms-transition:all 0.3s ease;
    -o-transition:all 0.3s ease;
    transition:all 0.3s ease;
    opacity:0;
}


#navigation-mobile li .navigation-mobile-container{
	background:#931b1f;
}

#navigation-mobile li.active .navigation-mobile-container {
    visibility:visible;
    opacity:1;
}

#navigation-mobile .navigation-mobile-info h3 {
    color: #ffffff;
    font-family: "Knockout 27 A", "Knockout 27 B";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    letter-spacing: 2px;
    line-height: 44px;
    padding: 0 0 20px;
    text-transform:uppercase;
    width:100%;
}

#navigation-mobile .navigation-mobile-info p {
    color:#ef7578;
    font-size: 16px;
    line-height: 30px;
    width:100%;
}

#navigation-mobile .navigation-mobile-info ul {
    margin:30px 0 0;
    width:100%;
}

#navigation-mobile .navigation-mobile-info li a {
    border-top:1px solid #af3236;
    display:block;
    font-family: "Gotham A", "Gotham B", sans-serif;
    font-size:14px;
    letter-spacing: 1px;
    line-height: 24px;
    padding:10px 0;
    font-weight:500;
}

@media (max-width:500px) {
    #navigation-mobile .navigation-mobile-info li a {
		font-size:12px;
		letter-spacing: 1px;
		line-height: 24px;
    }
}



#navigation-mobile .navigation-mobile-info li a:hover {
    color:#FDBBBD;
    -webkit-transition:-webkit-transform all .3s ease;
    -moz-transition:all .3s ease;
    -ms-transition:all .3s ease;
    -o-transition:all .3s ease;
    transition:all .3s ease;
    border-top:1px solid #FDBBBD;
}

@media (max-width:767px) {

    #navigation-mobile {
		align-items:flex-start;
    }

    #navigation-mobile > ul {
		display:block;
		float:none;
		width:100%;
		padding:100px;
    }

    #navigation-mobile .navigation-mobile-container {
		position:static;
		display:none;
		padding:10px 40px 30px;
		height:auto;
		align-items:flex-start;
		width:100%;
    }

    #navigation-mobile .navigation-mobile-info h3,
    #navigation-mobile .navigation-mobile-info p {
		display:none;
    }

    #navigation-mobile li.active .navigation-mobile-container {
		display:block;
    }

}

@media (max-width:500px) {
    #navigation-mobile > ul {
		padding:70px 40px;
    }

    #navigation-mobile .navigation-mobile-container {
		padding:10px 20px;
    }

    #navigation-mobile .navigation-mobile-info ul {
		margin:0;
    }

    #navigation-mobile .navigation-mobile-info li:first-child a {
		border:none;
    }

}

/*--Hamburger--*/

#frame-header .container {
    position: relative;
}

.mobile-icon {
    z-index:999;
    top:2em;
    cursor:pointer;
    height:30px;
    text-align:right;
}

.mobile-icon a {
    display:block;
    float:right;
}

.mobile-icon span {
    -ms-transform-origin: 50% 50%;
    -moz-transform-origin:50% 50%;
    -webkit-transform-origin:50% 50%;
    transform-origin: 50% 50%;;

    background:#f2efe9;
    display:block;
    height:2px;
    width:35px;
    position:relative;
}

.mobile-icon span.ham-top {
    top:0px;
    -moz-transform:rotate(0);
    -webkit-transform:rotate(0);
    transform:rotate(0);
}

.mobile-icon span.ham-middle {
    top:8px;
    -moz-transform:rotate(0);
    -webkit-transform:rotate(0);
    transform:rotate(0);
}

.mobile-icon span.ham-bottom {
    top:16px;
    -moz-transform:rotate(0);
    -webkit-transform:rotate(0);
    transform:rotate(0);
}

.mobile-icon.close span.ham-top {
    -ms-transform: rotate(-45deg);
    -moz-transform:rotate(-45deg);
    -webkit-transform:rotate(-45deg);
    transform:rotate(-45deg);

    top:10px;
    -moz-animation:menuCloseTop 0.3s ease-in;
    -webkit-animation:menuCloseTop 0.3s ease-in;
    -o-animation:menuCloseTop 0.3s ease-in;
    -ms-animation:menuCloseTop 0.3s ease-in;
    animation:menuCloseTop 0.3s ease-in;
}

.mobile-icon.close span.ham-middle {
    display:none;
}

.mobile-icon.close span.ham-bottom {
    -ms-transform: rotate(45deg); /* IE 9 */
    -moz-transform:rotate(45deg);
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg);

    top:8px;
    -moz-animation:menuCloseBot 0.3s ease-in;
    -webkit-animation:menuCloseBot 0.3s ease-in;
    -o-animation:menuCloseBot 0.3s ease-in;
    -ms-animation:menuCloseBot 0.3s ease-in;
    animation:menuCloseBot 0.3s ease-in;
}

.mobile-icon.open span.ham-top {
    -ms-transform: rotate(0deg);
    -moz-transform:rotate(0deg);
    -webkit-transform:rotate(0deg);
    transform:rotate(0deg);

    top:0px;
    -moz-animation:menuOpenTop 0.3s ease-in;
    -webkit-animation:menuOpenTop 0.3s ease-in;
    -o-animation:menuOpenTop 0.3s ease-in;
    -ms-animation:menuOpenTop 0.3s ease-in;
    animation:menuOpenTop 0.3s ease-in;
}

.mobile-icon.open span.ham-middle {
    display:block;
    -moz-animation:menuOpenMid 0.3s ease-in;
    -webkit-animation:menuOpenMid 0.3s ease-in;
    -o-animation:menuOpenMid 0.3s ease-in;
    -ms-animation:menuOpenMid 0.3s ease-in;
    animation:menuOpenMid 0.3s ease-in;

}

.mobile-icon.open span.ham-bottom {
    -ms-transform: rotate(0deg); /* IE 9 */
    -moz-transform:rotate(0deg);
    -webkit-transform:rotate(0deg);
    transform:rotate(0deg);

    top:16px;
    -moz-animation:menuOpenBot 0.3s ease-in;
    -webkit-animation:menuOpenBot 0.3s ease-in;
    -o-animation:menuOpenBot 0.3s ease-in;
    -ms-animation:menuOpenBot 0.3s ease-in;
    animation:menuOpenBot 0.3s ease-in;
}

@keyframes menuCloseTop {
    0% {
		top:0;
		-moz-transform:rotate(0deg);
		-webkit-transform:rotate(0deg);
		transform:rotate(0deg);
    }

    20% {
		top:10px;
		-moz-transform:rotate(0deg);
		-webkit-transform:rotate(0deg);
		transform:rotate(0deg);
    }

    50% {
		top:10px;
		-moz-transform:rotate(0deg);
		-webkit-transform:rotate(0deg);
		transform:rotate(0deg);
    }

    100% {
		-moz-transform:rotate(-45deg);
		-webkit-transform:rotate(-45deg);
		transform:rotate(-45deg);
    }
}

@keyframes menuCloseBot {
    0% {
		top:0;
		-moz-transform:rotate(0deg);
		-webkit-transform:rotate(0deg);
		transform:rotate(0deg);
    }

    20% {
		top:8px;
		-moz-transform:rotate(0deg);
		-webkit-transform:rotate(0deg);
		transform:rotate(0deg);
    }

    50% {
		top:8px;
		-moz-transform:rotate(0deg);
		-webkit-transform:rotate(0deg);
		transform:rotate(0deg);
    }

    100% {
		-moz-transform:rotate(45deg);
		-webkit-transform:rotate(45deg);
		transform:rotate(45deg);
    }
}

@keyframes menuOpenTop {
    0% {
		top:10px;
		-moz-transform:rotate(-45deg);
		-webkit-transform:rotate(-45deg);
		transform:rotate(-45deg);
    }

    50% {
		top:10px;
		-moz-transform:rotate(0deg);
		-webkit-transform:rotate(0deg);
		transform:rotate(0deg);
    }

    100% {
		top:0px;
    }
}

@keyframes menuOpenMid {
    0% {
		display:none;
		opacity:0;
    }

    70% {
		opacity:0;
		display:block;
    }

    100% {
		opacity:1;
    }
}


@keyframes menuOpenBot {
    0% {
		-moz-transform:rotate(45deg);
		-webkit-transform:rotate(45deg);
		transform:rotate(45deg);
		top:8px;
    }

    50% {
		top:8px;
		-moz-transform:rotate(0deg);
		-webkit-transform:rotate(0deg);
		transform:rotate(0deg);
    }

    100% {
		top:16px;
    }
}
